@use 'src/styles/common/variables' as *;

.component-title {
  font-family: $header_text;
  font-size: 24px;
  @media (max-width: $max_phone_portrait) {
    padding-bottom: 1vh;
  }
}

.component-subtitle {
  font-family: $main_text;
  font-size: 20px;
  @media (max-width: $max_phone_portrait) {
    padding-bottom: 1vh;
    font-size: 16px;
  }
}

.payment-input {
  width: 15vw;

  @media (max-width: $max_tablet_portrait) {
    width: 60vw;
  }
}

.payment-inputs-wrapper {
  margin-top: 2vh;
  display: flex;
  column-gap: 1vw;
  padding: 1px 1px;
  @media (max-width: $max_tablet_portrait) {
    flex-direction: column;
  }
}

.offer-payment-method-container {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  @media (max-width: $max_phone_portrait) {
    font-size: 12px;
  }
}

.payment-radio-group {
  display: flex;
  flex-direction: row;
  place-content: space-between;
  padding: 10px 0;
  column-gap: 0.8vw;
  @media (max-width: $max_phone_portrait) {
    margin: 1vh 0 2vh 0;
  }
}

.payment-visa-img {
  max-width: 32px;
}

.payment-mastercard-img {
  max-width: 30px;
}

.credit-card-images {
  display: flex;
  align-items: center;
  width: 12vw;
  justify-content: space-between;
  min-width: 280px;

  span {
    margin-right: 2vw;
  }

  img {
    margin: 0 0.5vw;
  }
}

.terms-approval-label {
  font-family: $main_text;
  margin-left: 2vw;

  @media (max-width: $max_tablet_portrait) {
    font-size: 16px;
  }
}

.terms-link {
  color: $black;
  font-family: $main_text_bold;
}


.payment-info-text, .payment-info-installments-text {
  padding: 0 40px;
  font-size: 12px;
  font-family: $main_text;
  margin-top: 15px;
  line-height: 1.5;
  @media (max-width: $max_phone_portrait) {
    font-size: 12px;
  }
}

.payment-info-text.renewal-price-text, .payment-info-text#subscription-info-continuous-subscription {
  margin-top: 5px;
  text-align: center;
}

.small-body-text {
  font-family: $main_text;
  font-size: 13px;
}


.label-container {
  position: relative;
}