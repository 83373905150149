@use 'src/styles/common/variables' as *;

.progress-bar-container {
  display: flex;
  justify-content: space-between;
  position: relative;
  max-width: 540px;
  font-size: 12px;
  line-height: 2.5em;
  padding-top: 1vh;
  align-items: center;
  margin: max(2.5vh, 20px) auto max(5vh, 40px);

  @media (max-width: $max_phone_landscape) {
    margin: max(2vh, 20px) 15% max(4vh, 40px);
  }

}

.ball {
  width: 9px;
  height: 9px;
  border-radius: 100%;
  border: 0.5px solid $black;
}

.active {
  background-color: $black;
}

.inactive {
  background-color: $white;
}

.ball-container {
  display: flex;
  flex-direction: column;
  z-index: 2;
  align-items: center;
}

.ball-line {
  position: absolute;
  width: 100%;
  width: stretch;
  z-index: 1;
}

.ball-title {
  position: absolute;
  padding-top: 1.2em;

  @media (max-width: $max_phone_portrait) {
    padding-top: 1.4em;
  }
}
