@use 'src/styles/common/variables' as *;

.toaster-container {
  background-color: $pink;
  position: fixed;
  z-index: 10;
  width: 85%;
  left: 0;
  right: 0;
  padding: 30px;
  top: 30px;
  margin: auto;
  box-shadow: 3px 15px 20px -1px rgb(0 0 0 / 5%), -3px 15px 20px -1px rgb(0 0 0 / 5%);
  border-radius: 3px;
  color: $dark_gray;
  font-family: $main_text;
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: 0;
}

.icon-text-pair {
  display: flex;
  align-items: center;
}

.warning-icon {
  margin-right: 20px;
}

.close-button {
  background: none;
  border: none;
  cursor: pointer;
}

.fade-in {
  transition: opacity 0.5s;
  opacity: 1;
}

.fade-out {
  transition: opacity 0.5s;
  opacity: 0;
}