@use './app';
@use './checkbox';
@use './page';
@use './payment';
@use './progress-bar';
@use './radio-button';
@use './common/buttons';
@use './input';
@use './order-contact';
@use './summary';
@use './popup-window';
@use './handle-order';
@use './spinner';
@use 'src/styles/common/variables' as *;
@use './login';
@use './error-page';
@use './order_info';
@use './common/fonts';
@use './toaster';

body {
  margin: 0;
  font-family: $main_text;
  background: $background;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  line-height: 1.15;
  color: #000000;
}

h1, h2, {
  font-family: $header_text;
  font-weight: bold;
  color: $black;
}

b {
  font-family: $main_text_bold;
}