@use 'src/styles/common/variables' as *;

$radio-size: 12px;

.radio-label {
  padding-left: 5px;
  display: flex;
  font-size: 16px;
}

.radio-pair {
  display: flex;
  align-items: center;
  margin: 1vh 0;

  input {
    position: relative;
    margin-bottom: 3.5px;
    height: $radio-size;
    width: $radio-size;
  }
}

%input-radio {
  width: $radio-size;
  height: $radio-size;
  border-radius: 100%;
  position: relative;
  background-color: $white;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 1px solid $black;
}

input[type='radio']:after {
  @extend %input-radio;
}

input[type='radio']:checked:before {
  @extend %input-radio;
}

input[type='radio']:checked:after {
  width: 6px;
  height: 6px;
  border-radius: 100%;
  top: 3px;
  left: 3px;
  position: absolute;
  background-color: $black;
  content: '';
  display: inline-block;
  visibility: visible;
}