@use 'src/styles/common/variables' as *;

.login-container {
  background-color: $very_light_gray;
  left: 0;
  position: absolute;
  width: 100%;
  margin-top: 5vh;
  display: flex;
  justify-content: center;
  height: 100%;
}

.login-content {
  width: 33vw;
  max-width: 600px;
  background-color: $white;
  border-radius: 3px;
  box-shadow: 3px 2px 2px -1px rgba(0,0,0,5%), -3px 5px 2px -1px rgba(0,0,0,5%);
  margin-top: 5vh;
  height: fit-content;

  @media (max-width: $max_tablet_portrait) {
    width: 50vw;
  }

  @media (max-width: $max_phone_portrait) {
    width: 90%;
    margin-top: 2vh;
  }
}

.text-container {
  padding: 2vh 4vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    @media (max-width: $max_phone_portrait) {
      font-size: 21px;
    }
  }
}

.pink-bar {
  background-color: $light_pink;
  width: 100%;
  height: fit-content;
  padding: 2vh 0;
}

.login-button {
  width: 100%;
  margin-top: 1vh;
}

.login-input {
  width: 100%;
  margin: 2vh 0;

  @media (max-width: $max_phone_portrait) {
    margin: 4vh 0;
  }
}