@use 'src/styles/common/variables' as *;


.order-information-input {
  width: 20vw;
  max-width: 240px;
  @media (max-width: $max_tablet_portrait) {
    width: 60vw;
    max-width: 505px;
  }
}

.input-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 25px;
  row-gap: 1vh;
  margin: 0 auto;


  @media (max-width: $max_tablet_portrait) {
    margin-bottom: max(3vh, 30px);
    grid-template-columns: 1fr;
  }
}

.address-input {
  box-sizing: border-box;
  width: 100%;
  max-width: 505px;
  @media (max-width: $max_tablet_portrait) {
    box-sizing: border-box;
    width: 60vw;
  }
}

.address-input-wrapper {
  @media (max-width: $max_tablet_portrait) {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
  }
  grid-column: 1 / 3;
  grid-row: 2 / 3;
}