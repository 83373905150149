@font-face {
  font-family: "Cadiz-Regular";
  src: local("Cadiz-Regular"),
    url(../../public/Cadiz/Cadiz-Regular.otf) format("opentype");
}

@font-face {
  font-family: "Cadiz-Bold";
  src: local("Cadiz-Bold"),
    url(../../public/Cadiz/Cadiz-Bold.otf) format("opentype");
}

@font-face {
  font-family: "NewBaskervilleStd-Bold";
  font-weight: bold;
  src: local("NewBaskervilleStd-Bold"),
    url(../../public/NewBaskervilleStd/NewBaskervilleStd-Bold.otf) format("opentype");
}