// COLORS
$very_light_gray: #f9f9f9;
$light_gray: #9f9f9f;
$white: #fff;
$background: #f4f4f4;
$light_pink: #fdf2f2;
$light_blue: #d1f7fc;
$gray: #808080;
$black: #313131;
$red: #ff0000;
$dark_gray: #626161;
$pink: #fddcdc;
$error_background: #FDF2F2;

// MAGAZINE COLORS
$otavamedia: #c2f2ee;

// FONTS
$main_text: 'Cadiz-Regular';
$main_text_bold: 'Cadiz-Bold';
$header_text: 'NewBaskervilleStd-Bold';

// BREAKPOINTS
$max_phone_portrait: 414px;
$max_phone_landscape: 768px;
$max_tablet_portrait: 992px;
$max_tablet_landscape: 1112px;
$max_desktop_hd: 1080px;
$max_desktop_2k: 1440px;

// VALUES
$page_side_margin_mobile: 5vw;