@use 'src/styles/common/variables' as *;

.label {
  display: block;
  font-size: 12px;
  font-family: $main_text;
  text-align: left;
  margin: 0.2em 0;
}

.input {
  display: block;
  box-sizing: border-box;
  border: 1px solid $black;
  padding: 8px;
  font-size: 14px;
  font-family: $main_text;

  &:focus {
    outline: none;
  }

  @media (max-width: $max_phone_portrait) {
    width: 100%;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.invalid {
  border: 1px solid $red;
}

.error-msg {
  font-size: 12px;
  color: $red;
}

.disabled-input {
  border: 1px solid $gray;
  color: $gray;
  background: white;
}

.disabled-label {
  color: $gray;
}